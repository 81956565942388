import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface VoiceOption {
  voice: SpeechSynthesisVoice;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  private synth: SpeechSynthesis;
  private utterance: SpeechSynthesisUtterance;
  private voices: SpeechSynthesisVoice[] = [];
  private endCallback: () => void = () => { };
  private voicesLoaded = new BehaviorSubject<boolean>(false);

  constructor() {
    this.synth = window.speechSynthesis;
    this.utterance = new SpeechSynthesisUtterance();

    // Load voices when available
    if (this.synth.onvoiceschanged !== undefined) {
      this.synth.onvoiceschanged = () => {
        this.voices = this.synth.getVoices();
        this.voicesLoaded.next(true);
      };
    }

    // Initial load of voices
    setTimeout(() => {
      this.voices = this.synth.getVoices();
      this.voicesLoaded.next(true);
    }, 100);

    // Set event listeners
    this.utterance.onend = () => {
      this.endCallback();
    };
  }

  public getVoicesLoadedState(): Observable<boolean> {
    return this.voicesLoaded.asObservable();
  }

  public getAvailableVoices(): VoiceOption[] {
    return this.voices.map(voice => ({
      voice,
      label: `${voice.name} (${voice.lang})`
    }));
  }

  public setVoice(voice: SpeechSynthesisVoice): void {
    this.utterance.voice = voice;
  }

  public speak(text: string): void {
    this.stop();
    this.utterance.text = text;
    this.synth.speak(this.utterance);
  }

  public stop(): void {
    this.synth.cancel();
  }

  public onEnd(callback: () => void): void {
    this.endCallback = callback;
  }
}