import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  private isVisibleSubject = new BehaviorSubject<boolean>(false);
  public isVisible$ = this.isVisibleSubject.asObservable();

  private isExpandedSubject = new BehaviorSubject<boolean>(false);
  public isExpanded$ = this.isExpandedSubject.asObservable();

  private isFullscreenSubject = new BehaviorSubject<boolean>(false);
  public isFullscreen$ = this.isFullscreenSubject.asObservable();

  constructor() { }

  toggleVisibility() {
    this.isVisibleSubject.next(!this.isVisibleSubject.value);
    // Reset expanded/fullscreen states if toggling off
    if (!this.isVisibleSubject.value) {
      this.isExpandedSubject.next(false);
      this.isFullscreenSubject.next(false);
    }
  }

  show() {
    this.isVisibleSubject.next(true);
  }

  hide() {
    this.isVisibleSubject.next(false);
    this.isExpandedSubject.next(false);
    this.isFullscreenSubject.next(false);
  }

  showExpanded() {
    // If already visible, hide it instead (toggle behavior)
    if (this.isVisibleSubject.value) {
      this.hide();
      return;
    }

    // Otherwise show expanded
    this.isVisibleSubject.next(true);
    this.isExpandedSubject.next(true);
    this.isFullscreenSubject.next(false);
  }

  toggleExpanded() {
    const isCurrentlyExpanded = this.isExpandedSubject.value;
    const isCurrentlyFullscreen = this.isFullscreenSubject.value;

    if (!isCurrentlyExpanded) {
      // If not expanded, expand it
      this.isExpandedSubject.next(true);
      this.isFullscreenSubject.next(false);
    } else if (!isCurrentlyFullscreen) {
      // If expanded but not fullscreen, go to fullscreen
      this.isFullscreenSubject.next(true);
    } else {
      // If fullscreen, go back to expanded
      this.isFullscreenSubject.next(false);
    }
  }
}