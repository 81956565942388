.chat-container {
  position: fixed;
  right: 30px;
  bottom: 100px;
  width: 500px; // Increased from 400px to 500px
  height: 600px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 99999; // Increased z-index
  transition: all 0.3s ease;

  &.expanded {
    width: 500px; // Increased from 400px to 500px
    height: 600px;
  }

  &.fullscreen {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 100001; // Higher than backdrop
    overflow: hidden; // Ensure no overflow
  }
}

.chat-card {
  display: flex;
  flex-direction: column;
  height: 600px;
  max-height: 80vh;
  width: 100%; // Make sure card takes full container width
  border-radius: 12px;
  overflow: hidden;
  margin: 0;
  box-shadow: none;
  position: relative;

  &.fullscreen {
    border-radius: 0;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: none;
  }
}

.chat-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: var(--mat-theme-primary-color, #6a1b9a);
  color: white;
  z-index: 3;

  &-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.chat-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.chat-actions {
  display: flex;
  gap: 8px;

  button {
    color: white;
    width: 32px;
    height: 32px;
    line-height: 32px;

    .mat-icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f5f5f5;
  height: calc(100% - 120px); // Increased space for messages by reducing footer allocation
  scroll-behavior: smooth;

  // Special styling for fullscreen mode
  .fullscreen & {
    height: calc(100vh - 120px);
    padding: 16px 24px;
    margin-bottom: 60px; // Add margin for footer
  }
}

.message {
  max-width: 80%;

  &.user-message {
    align-self: flex-end;

    .message-content {
      background-color: var(--mat-theme-primary-color, #6a1b9a);
      color: white;
      border-radius: 12px 12px 0 12px;

      &.editing {
        background-color: rgba(var(--mat-theme-primary-color-rgb, 74, 18, 105), 0.8);

        ::ng-deep .mat-mdc-form-field {
          .mdc-text-field {
            background-color: rgba(255, 255, 255, 0.1);
          }

          .mat-mdc-form-field-flex {
            background-color: transparent;
          }

          .mdc-floating-label,
          .mat-mdc-input-element {
            color: white;
          }
        }
      }

      .message-actions {
        button {
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  &.bot-message {
    align-self: flex-start;

    .message-content {
      background-color: white;
      border-radius: 12px 12px 12px 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .message-actions {
        button {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.message-content {
  padding: 12px;
  position: relative;

  p {
    margin: 0;
    line-height: 1.4;
  }
}

.message-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.message-actions {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: auto;

  .action-button {
    width: 24px;
    height: 24px;
    line-height: 24px;

    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
}

.timestamp {
  font-size: 0.8rem;
  opacity: 0.7;
  flex-shrink: 0;
}

.edit-container {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;

  .edit-input {
    width: 100%;
    margin: 0;

    ::ng-deep .mat-mdc-form-field-flex {
      background-color: rgba(255, 255, 255, 0.1);
    }

    ::ng-deep .mat-mdc-form-field-infix {
      padding: 8px 0;
    }

    ::ng-deep textarea {
      color: white !important;

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .edit-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 8px;

    .edit-button {
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.suggested-services-container {
  margin-top: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    background: linear-gradient(to right, transparent, #f5f5f5);
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40px;
    background: linear-gradient(to left, transparent, #f5f5f5);
    pointer-events: none;
    z-index: 1;
  }
}

.suggested-services-grid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  gap: 8px;
  padding: 4px;
  animation: scrollServices 60s linear infinite; // Increased from 30s to 60s for slower movement
  width: max-content;

  &:hover {
    animation-play-state: paused;
  }

  button {
    font-size: 0.9rem;
    white-space: nowrap;
    min-height: 32px;
    line-height: 32px;
    padding: 0 16px;
    flex-shrink: 0;
    transition: transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;

    mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }

    span {
      margin-left: 4px;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }
}

@keyframes scrollServices {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.input-container {
  position: sticky;
  bottom: 0;
  padding: 4px 8px;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease;
  z-index: 3;
  display: flex;
  flex-direction: column; // Changed to column layout
  min-height: 80px; // Increased slightly for the stacked layout

  &.expanded {
    height: 300px;
    min-height: 300px; // Use min-height for expanded mode
    display: flex;
    flex-direction: column;
    align-items: stretch; // Change alignment for expanded mode
    background: white;
    padding: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0 0;

    .input-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      padding: 0 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding-bottom: 12px;

      span {
        font-weight: 500;
        color: #333;
        font-size: 14px;
      }

      .header-actions {
        display: flex;
        gap: 4px;
        align-items: center;

        button {
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }

          .mat-icon {
            font-size: 18px;
            width: 18px;
            height: 18px;
            color: #666;
          }
        }
      }
    }

    .message-input {
      flex: 1;
      display: flex;
      flex-direction: column;

      ::ng-deep .mat-mdc-form-field-flex {
        height: 100%;
        background-color: #f8f8f8;
        border-radius: 8px;
      }

      textarea {
        height: calc(100% - 24px) !important;
        padding: 12px !important;
      }
    }

    .input-actions {
      margin-top: 8px; // Reset margin for expanded mode
      justify-content: flex-end;
      padding: 0 8px;

      button {
        transform: none; // Reset transform for expanded mode
      }
    }
  }

  .fullscreen & {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 8px 16px;
    min-height: 60px;
    display: flex;
    align-items: center;
    z-index: 4;

    &.expanded {
      min-height: 250px; // Set a good height for expanded input in fullscreen
      height: 250px; // Fixed height in fullscreen expanded mode
      flex-direction: column; // Stack elements in expanded mode
      align-items: stretch; // Stretch children to container width
      padding: 12px 16px; // Increase padding in expanded mode
      transition: all 0.3s ease;
      box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 0;

      .input-header {
        padding: 0 16px 12px;
      }

      .message-input {
        margin: 0 16px;
      }

      .input-actions {
        padding: 0 16px;
      }
    }
  }

  .input-actions {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    padding: 0;
    margin-top: -8px; // Added negative margin to move icons up
    height: 32px;
    width: 100%;

    &.hidden {
      display: none;
    }

    button {
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      transform: translateY(-4px); // Added transform to move icons up

      .mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.input-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 8px;

  span {
    font-weight: 500;
    color: #333;
  }

  .header-actions {
    display: flex;
    gap: 8px;

    button {
      width: 30px;
      height: 30px;
      line-height: 30px;

      .mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
        color: #666;
      }
    }
  }
}

.message-input {
  width: 100%; // Take full width
  margin: 4px 0; // Add some vertical margin
  position: relative;

  &.expanded {
    flex: 1;
    margin: 0; // Reset margin in expanded mode

    textarea {
      height: 100%;
    }
  }

  ::ng-deep .mat-mdc-form-field-flex {
    background-color: #f5f5f5;
    border-radius: 24px;
    padding: 0 8px; // Slightly increased padding
    transition: all 0.3s ease;
    min-height: 36px; // Better minimum height
  }

  ::ng-deep .mdc-notched-outline {
    display: none;
  }

  ::ng-deep .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  ::ng-deep .mat-mdc-form-field-infix {
    padding: 6px 0 !important; // Slightly increased padding
    min-height: 36px !important; // Better minimum height
  }

  textarea {
    resize: none;
    padding: 6px 35px 6px 8px !important; // Better padding
    line-height: 1.4;
    min-height: 24px; // Better minimum height
    font-size: 14px;
  }

  .fullscreen & {
    flex: 1;
    margin: 0 8px;

    ::ng-deep .mat-mdc-form-field-flex {
      min-height: 40px; // Slightly taller in fullscreen
    }
  }

  .fullscreen .input-container.expanded & {
    flex: 1; // Take all available space
    margin: 8px 0; // Add vertical margins

    ::ng-deep .mat-mdc-form-field-flex {
      min-height: 100%; // Fill the container height
      background-color: #f5f5f5;
    }

    textarea {
      min-height: 180px; // Set minimum height for textarea in expanded mode
    }
  }
}

.expand-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 32px;
  height: 32px;
  line-height: 32px;

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: #666;
  }
}

.input-actions {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  height: 32px;
  width: 100%; // Full width for the actions row

  button {
    width: 30px; // Better button size
    height: 30px; // Better button size
    line-height: 30px;
    padding: 0;

    .mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }
  }

  .fullscreen & {
    height: 40px; // Taller in fullscreen
    display: flex;
    align-items: center;

    button {
      width: 32px; // Larger buttons in fullscreen
      height: 32px; // Larger buttons in fullscreen
      line-height: 32px;
      margin-left: 5px; // Add spacing between buttons

      .mat-icon {
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (max-width: 600px) {
  .chat-container {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
    border-radius: 0;
    margin: 0;
    padding: 0;
    z-index: 999999;
    background-color: #f5f5f5;
    overflow: hidden;

    .chat-card {
      border-radius: 0;
      height: 100vh;
      max-height: 100vh;
      width: 100vw;
      margin: 0;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      background-color: white;
    }

    .messages-container {
      flex: 1;
      overflow-y: auto;
      padding: 12px;
      margin: 0;
      background-color: #f5f5f5;
      height: auto;
      max-height: unset;
      margin-bottom: 60px;
      /* Make room for fixed footer */
    }

    .input-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 8px;
      min-height: 60px;
      background-color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      z-index: 100002;
    }

    .chat-header {
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 100002;
    }
  }
}

::ng-deep {
  .cdk-overlay-container {
    z-index: 1000000;
  }

  .voice-settings-dialog-panel,
  .chat-history-dialog-panel {
    z-index: 1100000 !important;
    position: relative !important;
  }

  .cdk-overlay-backdrop {
    z-index: 1050000 !important;
  }

  .cdk-global-overlay-wrapper {
    z-index: 1050000 !important;
  }

  .fullscreen-dialog {
    // Additional styling for dialogs in fullscreen mode
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .fullscreen-dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .mat-dialog-container {
    display: flex;
    flex-direction: column;
    max-height: 80vh !important;
    padding: 0 !important;
  }

  .mat-dialog-content {
    max-height: unset !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow-y: auto !important;
  }

  .mat-dialog-actions {
    margin-bottom: 0 !important;
    padding: 8px 16px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.chat-history-header {
  padding: 16px;
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-history-list {
  padding: 8px 0;
}

.chat-history-item {
  padding: 8px 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.chat-history-item-content {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  .mat-icon {
    color: #666;
  }
}

.chat-history-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chat-history-title {
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-history-date {
  font-size: 0.8rem;
  color: #666;
}

.delete-session {
  opacity: 0;
  transition: opacity 0.2s ease;

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    color: #666;
  }
}

.chat-history-item:hover .delete-session {
  opacity: 1;
}

.chat-history-empty {
  padding: 16px;
  text-align: center;
  color: #666;
}

.chat-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100000; // Lower than fullscreen chat
  pointer-events: none; // Allow clicking through the backdrop
}

.voice-menu-header {
  padding: 16px;
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.selected-voice {
  background-color: rgba(var(--mat-theme-primary-color-rgb, 106, 27, 154), 0.08) !important;
}