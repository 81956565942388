<div class="voice-settings-dialog" [dir]="direction">
    <h2 mat-dialog-title>{{ 'CHAT_BOT.VOICE_SETTINGS' | translate }}</h2>
    <mat-dialog-content>
        <div class="dialog-content">
            <div *ngIf="voices && voices.length > 0">
                <mat-list class="voice-list">
                    <mat-list-item *ngFor="let voiceOpt of voices" (click)="selectVoice(voiceOpt)"
                        [class.selected]="isSelectedVoice(voiceOpt)">
                        <div class="voice-item-content">
                            <mat-icon>{{ isSelectedVoice(voiceOpt) ? 'radio_button_checked' : 'radio_button_unchecked'
                                }}</mat-icon>
                            <div class="voice-details">
                                <span class="voice-name">{{voiceOpt.voice.name}}</span>
                                <span class="voice-lang">{{voiceOpt.voice.lang}}</span>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
            <div *ngIf="!voices || voices.length === 0" class="no-voices">
                {{ 'CHAT_BOT.NO_VOICES_AVAILABLE' | translate }}
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
    </mat-dialog-actions>
</div>