import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VoiceOption } from '../../../core/services/text-to-speech.service';

@Component({
    selector: 'app-voice-settings-dialog',
    templateUrl: './voice-settings-dialog.component.html',
    styleUrls: ['./voice-settings-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        TranslateModule
    ]
})
export class VoiceSettingsDialogComponent {
    direction = this._translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    voices = this.data.voices || [];
    selectedVoice = this.data.selectedVoice;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            voices: VoiceOption[],
            selectedVoice?: SpeechSynthesisVoice
        },
        private dialogRef: MatDialogRef<VoiceSettingsDialogComponent>,
        private _translate: TranslateService
    ) {
        // Ensure we have access to the voices array
        if (!this.voices) {
            this.voices = [];
        }
    }

    selectVoice(voiceOption: VoiceOption): void {
        this.dialogRef.close(voiceOption.voice);
    }

    isSelectedVoice(voiceOption: VoiceOption): boolean {
        // Compare by name and language instead of direct object reference
        if (!this.selectedVoice || !voiceOption.voice) return false;
        return this.selectedVoice.name === voiceOption.voice.name &&
            this.selectedVoice.lang === voiceOption.voice.lang;
    }
}