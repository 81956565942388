<div class="chat-history-dialog" [dir]="direction">
    <h2 mat-dialog-title>{{ 'CHAT_BOT.CHAT_HISTORY' | translate }}</h2>
    <mat-dialog-content>
        <div class="dialog-content">
            <mat-list class="chat-history-list" *ngIf="chatSessions.length > 0">
                <mat-list-item *ngFor="let session of chatSessions" (click)="loadChatSession(session)"
                    class="chat-history-item">
                    <div class="chat-history-item-content">
                        <mat-icon>chat</mat-icon>
                        <div class="chat-history-item-details">
                            <span class="chat-history-title">{{session.title}}</span>
                            <span class="chat-history-date">{{session.timestamp | date:'short'}}</span>
                        </div>
                        <button mat-icon-button (click)="deleteChatSession($event, session.id)" class="delete-session">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>

            <div class="chat-history-empty" *ngIf="chatSessions.length === 0">
                <span>{{'CHAT_BOT.NO_CHAT_HISTORY' | translate}}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
    </mat-dialog-actions>
</div>