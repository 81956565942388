import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { TextToSpeechService, VoiceOption } from '../../core/services/text-to-speech.service';
import { ChatHistoryService, ChatSession } from '../../core/services/chat-history.service';
import { ChatBotService } from './chat-bot.service';
import { VoiceSettingsDialogComponent } from './voice-settings-dialog/voice-settings-dialog.component';
import { ChatHistoryDialogComponent } from './chat-history-dialog/chat-history-dialog.component';
import { Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

interface ChatMessage {
  text: string;
  isUser: boolean;
  timestamp: Date;
  isEditing?: boolean;
  editText?: string;
  isPlaying?: boolean;
}

interface ServiceOption {
  text: string;
  icon: string;
}

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    TranslateModule,
    VoiceSettingsDialogComponent,
    ChatHistoryDialogComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class ChatBotComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Output() minimize = new EventEmitter<void>();
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;

  isExpanded = false;
  isMinimized = false;
  isFullscreen = false;
  isInputExpanded = false;
  message = '';
  messages: ChatMessage[] = [];
  chatSessions: ChatSession[] = [];
  availableVoices: VoiceOption[] = [];
  selectedVoice?: SpeechSynthesisVoice;
  isVoiceDialogOpen = false; // Public property to track dialog state
  isChatHistoryDialogOpen = false; // Public property to track chat history dialog state
  private voiceDialogRef: MatDialogRef<VoiceSettingsDialogComponent> | null = null;
  private chatHistoryDialogRef: MatDialogRef<ChatHistoryDialogComponent> | null = null;

  private expandedSubscription: Subscription = new Subscription();
  private fullscreenSubscription: Subscription = new Subscription();

  public allServices: ServiceOption[] = [
    { text: this._translate.instant('CHAT_BOT.SERVICES.SALES_TRENDS'), icon: 'insights' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.CUSTOMER_BEHAVIOR'), icon: 'show_chart' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.BEST_PRODUCTS'), icon: 'bar_chart' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.SALES_REPORT'), icon: 'assessment' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.INVENTORY_STATUS'), icon: 'inventory' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.TOP_BRANCHES'), icon: 'location_on' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.RESTOCK_PRODUCTS'), icon: 'shopping_cart' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.STAFF_ALLOCATION'), icon: 'group' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.IMPROVE_PROFITABILITY'), icon: 'trending_up' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.IMPROVE_DELIVERY'), icon: 'local_shipping' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.REDUCE_COSTS'), icon: 'savings' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.CRM_STRATEGY'), icon: 'handshake' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.CREATE_INVOICE'), icon: 'description' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.ONBOARD_USER'), icon: 'person_add' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.INVENTORY_TRAINING'), icon: 'school' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.ERROR_HELP'), icon: 'error_outline' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.GO_DASHBOARD'), icon: 'dashboard' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.RECENT_TRANSACTIONS'), icon: 'history' },
    { text: this._translate.instant('CHAT_BOT.SERVICES.PAYMENT_REMINDER'), icon: 'alarm' }
  ];

  constructor(
    private tts: TextToSpeechService,
    private snackBar: MatSnackBar,
    private chatHistory: ChatHistoryService,
    private chatBotService: ChatBotService,
    private dialog: MatDialog,
    public _translate: TranslateService
  ) { }

  ngOnInit() {
    this.addMessage(this._translate.instant('CHAT_BOT.WELCOME_MESSAGE'), false);
    this.loadChatSessions();
    this.loadVoices();

    // Subscribe to expanded state from service
    this.expandedSubscription = this.chatBotService.isExpanded$.subscribe(expanded => {
      this.isExpanded = expanded;
      if (this.isExpanded) {
        this.isMinimized = false;
        setTimeout(() => this.scrollToBottom(), 100);
      }
    });

    // Subscribe to fullscreen state from service
    this.fullscreenSubscription = this.chatBotService.isFullscreen$.subscribe(fullscreen => {
      this.isFullscreen = fullscreen;
      setTimeout(() => this.scrollToBottom(), 100);
    });
  }

  ngOnDestroy() {
    this.expandedSubscription.unsubscribe();
    this.fullscreenSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private loadVoices(): void {
    // Wait a bit for voices to load
    setTimeout(() => {
      this.availableVoices = this.tts.getAvailableVoices();
    }, 100);
  }

  selectVoice(voice: SpeechSynthesisVoice): void {
    this.selectedVoice = voice;
    this.tts.setVoice(voice);
    this.snackBar.open(
      this._translate.instant('CHAT_BOT.VOICE_CHANGED', { name: voice.name }),
      this._translate.instant('COMMON.CLOSE'),
      { duration: 2000 }
    );
  }

  isSelectedVoice(voice: SpeechSynthesisVoice): boolean {
    return this.selectedVoice === voice;
  }

  private loadChatSessions(): void {
    this.chatSessions = this.chatHistory.getChatSessions();
  }

  private scrollToBottom(): void {
    try {
      const element = this.messagesContainer.nativeElement;
      element.scrollTop = element.scrollHeight;
    } catch (err) { }
  }

  toggleExpand() {
    this.chatBotService.toggleExpanded();
  }

  toggleMinimize() {
    // Save current chat before minimizing
    if (this.messages.length > 1) {
      this.chatHistory.saveChatSession(this.messages);
      this.loadChatSessions();
    }
    this.isExpanded = false;
    this.chatBotService.hide();
    this.minimize.emit();
  }

  toggleInputExpand() {
    this.isInputExpanded = !this.isInputExpanded;
  }

  selectService(service: string) {
    this.addMessage(service, true);
    // Simulate AI response
    setTimeout(() => {
      this.addMessage(`I'll help you with ${service}. What specific information do you need?`, false);
    }, 1000);
  }

  sendMessage() {
    if (this.message.trim()) {
      this.addMessage(this.message, true);
      this.message = '';
      // Simulate AI response
      setTimeout(() => {
        this.addMessage(this._translate.instant('CHAT_BOT.PROCESSING_REQUEST'), false);
      }, 1000);
    }
  }

  private addMessage(text: string, isUser: boolean) {
    this.messages.push({
      text,
      isUser,
      timestamp: new Date(),
      isEditing: false,
      isPlaying: false
    });

    setTimeout(() => this.scrollToBottom(), 100);
  }

  loadChatSession(session: ChatSession): void {
    this.messages = session.messages.map(msg => ({
      ...msg,
      timestamp: new Date(msg.timestamp),
      isEditing: false,
      isPlaying: false
    }));
    this.scrollToBottom();
  }

  deleteChatSession(event: Event, sessionId: string): void {
    event.stopPropagation();
    this.chatHistory.deleteSession(sessionId);
    this.loadChatSessions();
    this.snackBar.open(
      this._translate.instant('CHAT_BOT.CHAT_SESSION_DELETED'),
      this._translate.instant('COMMON.CLOSE'),
      { duration: 2000 }
    );
  }

  startNewChat(): void {
    if (this.messages.length > 1) {
      this.chatHistory.saveChatSession(this.messages);
    }
    this.messages = [];
    this.addMessage(this._translate.instant('CHAT_BOT.WELCOME_MESSAGE'), false);
    this.loadChatSessions();
  }

  togglePlayMessage(message: ChatMessage): void {
    if (message.isPlaying) {
      this.tts.stop();
      message.isPlaying = false;
      this.snackBar.open(
        this._translate.instant('CHAT_BOT.STOPPED_PLAYING'),
        this._translate.instant('COMMON.CLOSE'),
        {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        }
      );
    } else {
      // Stop any other playing messages
      this.messages.forEach(msg => {
        if (msg !== message && msg.isPlaying) {
          msg.isPlaying = false;
        }
      });

      message.isPlaying = true;
      this.tts.speak(message.text);
      this.snackBar.open(
        this._translate.instant('CHAT_BOT.PLAYING_MESSAGE'),
        this._translate.instant('COMMON.CLOSE'),
        {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        }
      );

      // Listen for speech end
      this.tts.onEnd(() => {
        message.isPlaying = false;
      });
    }
  }

  copyMessage(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.snackBar.open(
        this._translate.instant('CHAT_BOT.MESSAGE_COPIED'),
        this._translate.instant('COMMON.CLOSE'),
        {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        }
      );
    });
  }

  startEditMessage(message: ChatMessage): void {
    message.isEditing = true;
    message.editText = message.text;
  }

  saveEditMessage(message: ChatMessage): void {
    if (message.editText?.trim()) {
      // Update the message text
      message.text = message.editText;
      message.isEditing = false;
      message.editText = undefined;

      // Remove all messages that came after this message
      const messageIndex = this.messages.indexOf(message);
      if (messageIndex !== -1) {
        this.messages = this.messages.slice(0, messageIndex + 1);
      }

      // Simulate AI response to the edited message
      setTimeout(() => {
        this.addMessage(this._translate.instant('CHAT_BOT.PROCESSING_REQUEST'), false);
      }, 1000);
    }
  }

  cancelEditMessage(message: ChatMessage): void {
    message.isEditing = false;
    message.editText = undefined;
  }

  attachFile() {
    // Show message that feature is not enabled
    this.snackBar.open(
      this._translate.instant('CHAT_BOT.FEATURE_NOT_ENABLED'),
      this._translate.instant('COMMON.CLOSE'),
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    );
  }

  startVoiceChat() {
    // Show message that feature is not enabled
    this.snackBar.open(
      this._translate.instant('CHAT_BOT.FEATURE_NOT_ENABLED'),
      this._translate.instant('COMMON.CLOSE'),
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    );
  }

  toggleVoiceSettingsDialog(): void {
    if (this.voiceDialogRef) {
      this.voiceDialogRef.close();
      this.voiceDialogRef = null;
      this.isVoiceDialogOpen = false;
      return;
    }

    this.loadVoices();

    this.isVoiceDialogOpen = true;
    this.voiceDialogRef = this.dialog.open(VoiceSettingsDialogComponent, {
      width: '500px',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        voices: this.availableVoices,
        selectedVoice: this.selectedVoice
      },
      panelClass: ['voice-settings-dialog-panel', this.isFullscreen ? 'fullscreen-dialog' : ''],
      backdropClass: this.isFullscreen ? 'fullscreen-dialog-backdrop' : '',
      hasBackdrop: true,
      disableClose: false
    });

    this.voiceDialogRef.afterClosed().subscribe(result => {
      this.voiceDialogRef = null;
      this.isVoiceDialogOpen = false;
      if (result) {
        this.selectedVoice = result;
        this.tts.setVoice(result);
      }
    });
  }

  toggleChatHistoryDialog(): void {
    if (this.chatHistoryDialogRef) {
      this.chatHistoryDialogRef.close();
      this.chatHistoryDialogRef = null;
      this.isChatHistoryDialogOpen = false;
      return;
    }

    this.isChatHistoryDialogOpen = true;
    this.chatHistoryDialogRef = this.dialog.open(ChatHistoryDialogComponent, {
      width: '500px',
      maxWidth: '90vw',
      autoFocus: true,
      data: { chatSessions: this.chatSessions },
      panelClass: ['chat-history-dialog-panel', this.isFullscreen ? 'fullscreen-dialog' : ''],
      backdropClass: this.isFullscreen ? 'fullscreen-dialog-backdrop' : '',
      hasBackdrop: true,
      disableClose: false
    });

    this.chatHistoryDialogRef.afterClosed().subscribe(result => {
      this.chatHistoryDialogRef = null;
      this.isChatHistoryDialogOpen = false;

      if (result) {
        if (result.action === 'delete' && result.sessionId) {
          // Handle session deletion
          this.chatHistory.deleteSession(result.sessionId);
          this.loadChatSessions();
          this.snackBar.open(this._translate.instant('CHAT_BOT.CHAT_SESSION_DELETED'), this._translate.instant('COMMON.CLOSE'), {
            duration: 2000
          });
        } else {
          // Handle loading a session
          this.loadChatSession(result);
        }
      }
    });
  }

  isLastUserMessage(message: ChatMessage): boolean {
    // Find the last user message in the messages array
    const lastUserMessage = [...this.messages].reverse().find(msg => msg.isUser);
    // Return true if this message is the last user message
    return message === lastUserMessage;
  }
}