<div class="chat-container" [class.expanded]="isExpanded" [class.fullscreen]="isFullscreen">
  <mat-card class="chat-card" [class.fullscreen]="isFullscreen">
    <div class="chat-header">
      <div class="chat-header-left">
        <span class="chat-title">{{ 'CHAT_BOT.TITLE' | translate }}</span>
      </div>
      <div class="chat-actions">
        <button mat-icon-button
          [matTooltip]="(isChatHistoryDialogOpen ? 'CHAT_BOT.CLOSE_CHAT_HISTORY' : 'CHAT_BOT.CHAT_HISTORY') | translate"
          (click)="toggleChatHistoryDialog()">
          <mat-icon>history</mat-icon>
        </button>
        <button mat-icon-button
          [matTooltip]="(isVoiceDialogOpen ? 'CHAT_BOT.CLOSE_VOICE_SETTINGS' : 'CHAT_BOT.VOICE_SETTINGS') | translate"
          (click)="toggleVoiceSettingsDialog()">
          <mat-icon>settings_voice</mat-icon>
        </button>
        <button mat-icon-button [matTooltip]="'CHAT_BOT.NEW_CHAT' | translate" (click)="startNewChat()">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button
          [matTooltip]="(isFullscreen ? 'CHAT_BOT.EXIT_FULLSCREEN' : 'CHAT_BOT.FULLSCREEN') | translate"
          (click)="toggleExpand()">
          <mat-icon>{{isFullscreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
        </button>
        <button *ngIf="isFullscreen" mat-icon-button [matTooltip]="'CHAT_BOT.CLOSE' | translate"
          (click)="toggleMinimize()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="messages-container" #messagesContainer>
      <div *ngFor="let msg of messages" [class.user-message]="msg.isUser" [class.bot-message]="!msg.isUser"
        class="message">
        <div class="message-content" [class.editing]="msg.isEditing">
          <ng-container *ngIf="!msg.isEditing">
            <p>{{ msg.text }}</p>
            <div class="message-footer">
              <small class="timestamp">{{ msg.timestamp | date:'shortTime' }}</small>
              <div class="message-actions">
                <button mat-icon-button class="action-button" (click)="copyMessage(msg.text)"
                  [matTooltip]="'CHAT_BOT.COPY_MESSAGE' | translate">
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button *ngIf="!msg.isUser" mat-icon-button class="action-button" (click)="togglePlayMessage(msg)"
                  [matTooltip]="(msg.isPlaying ? 'CHAT_BOT.STOP_MESSAGE' : 'CHAT_BOT.PLAY_MESSAGE') | translate">
                  <mat-icon>{{msg.isPlaying ? 'volume_off' : 'volume_up'}}</mat-icon>
                </button>
                <button *ngIf="msg.isUser && isLastUserMessage(msg)" mat-icon-button class="action-button"
                  (click)="startEditMessage(msg)" [matTooltip]="'CHAT_BOT.EDIT_MESSAGE' | translate">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>

          <div *ngIf="msg.isEditing" class="edit-container">
            <mat-form-field appearance="outline" class="edit-input">
              <textarea matInput [(ngModel)]="msg.editText" rows="3"></textarea>
            </mat-form-field>
            <div class="edit-actions">
              <button mat-button (click)="saveEditMessage(msg)" class="edit-button">{{ 'CHAT_BOT.RESEND' | translate
                }}</button>
              <button mat-button (click)="cancelEditMessage(msg)" class="edit-button">{{ 'CHAT_BOT.CANCEL' | translate
                }}</button>
            </div>
          </div>
        </div>

        <div *ngIf="!msg.isUser && messages[0] === msg" class="suggested-services-container">
          <div class="suggested-services-grid">
            <ng-container *ngFor="let service of allServices">
              <button mat-stroked-button (click)="selectService(service.text)" color="primary">
                <mat-icon>{{service.icon}}</mat-icon>
                <span>{{ service.text }}</span>
              </button>
            </ng-container>
            <ng-container *ngFor="let service of allServices">
              <button mat-stroked-button (click)="selectService(service.text)" color="primary">
                <mat-icon>{{service.icon}}</mat-icon>
                <span>{{ service.text }}</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="input-container" [class.expanded]="isInputExpanded">
      <div class="input-header" *ngIf="isInputExpanded">
        <span>{{ 'CHAT_BOT.MESSAGE' | translate }}</span>
        <div class="header-actions">
          <button mat-icon-button (click)="attachFile()" [matTooltip]="'CHAT_BOT.ATTACH_FILE' | translate">
            <mat-icon>attach_file</mat-icon>
          </button>
          <button mat-icon-button (click)="startVoiceChat()" [matTooltip]="'CHAT_BOT.VOICE_INPUT' | translate">
            <mat-icon>mic</mat-icon>
          </button>
          <button mat-icon-button (click)="toggleInputExpand()" [matTooltip]="'CHAT_BOT.MINIMIZE' | translate">
            <mat-icon>close_fullscreen</mat-icon>
          </button>
        </div>
      </div>
      <mat-form-field appearance="outline" class="message-input" [class.expanded]="isInputExpanded">
        <textarea matInput [(ngModel)]="message" [placeholder]="'CHAT_BOT.TYPE_MESSAGE' | translate"
          [rows]="isInputExpanded ? 8 : 1" (keyup.enter)="sendMessage()"></textarea>
        <button *ngIf="!isFullscreen && !isInputExpanded" mat-icon-button class="expand-button"
          (click)="toggleInputExpand()">
          <mat-icon>open_in_full</mat-icon>
        </button>
        <button *ngIf="isFullscreen && !isInputExpanded" mat-icon-button class="expand-button"
          (click)="toggleInputExpand()" [matTooltip]="'CHAT_BOT.EXPAND_INPUT' | translate">
          <mat-icon>vertical_align_top</mat-icon>
        </button>
      </mat-form-field>

      <div class="input-actions">
        <button mat-icon-button (click)="attachFile()" [matTooltip]="'CHAT_BOT.ATTACH_FILE' | translate">
          <mat-icon>attach_file</mat-icon>
        </button>
        <button mat-icon-button (click)="startVoiceChat()" [matTooltip]="'CHAT_BOT.VOICE_INPUT' | translate">
          <mat-icon>mic</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="sendMessage()"
          [matTooltip]="'CHAT_BOT.SEND_MESSAGE' | translate">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>

<div class="chat-backdrop" *ngIf="isFullscreen"></div>