import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-chat-history-dialog',
    templateUrl: './chat-history-dialog.component.html',
    styleUrls: ['./chat-history-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        TranslateModule
    ]
})
export class ChatHistoryDialogComponent {
    direction = this._translate.currentLang === 'ar' ? 'rtl' : 'ltr';
    chatSessions = this.data.chatSessions;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ChatHistoryDialogComponent>,
        private _translate: TranslateService
    ) { }

    loadChatSession(session: any): void {
        this.dialogRef.close(session);
    }

    deleteChatSession(event: Event, sessionId: string): void {
        event.stopPropagation();
        this.dialogRef.close({ action: 'delete', sessionId });
    }
}