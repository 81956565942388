import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export interface ChatMessage {
  text: string;
  isUser: boolean;
  timestamp: Date | string;
}

export interface ChatSession {
  id: string;
  title: string;
  timestamp: Date | string;
  messages: ChatMessage[];
}

@Injectable({
  providedIn: 'root'
})
export class ChatHistoryService {
  private readonly STORAGE_KEY = '5dma_chat_history';

  constructor() { }

  // Save a new chat session
  public saveChatSession(messages: ChatMessage[]): void {
    if (messages.length <= 1) {
      return; // Don't save sessions with only the welcome message
    }

    const sessions = this.getChatSessions();

    // Create a title from the first user message or use default
    let title = 'Chat Session';
    const firstUserMessage = messages.find(msg => msg.isUser);
    if (firstUserMessage) {
      title = firstUserMessage.text.substring(0, 30) + (firstUserMessage.text.length > 30 ? '...' : '');
    }

    const newSession: ChatSession = {
      id: uuidv4(),
      title,
      timestamp: new Date(),
      messages
    };

    // Add to the beginning of the array (newest first)
    sessions.unshift(newSession);

    // Keep only the last 10 sessions
    const trimmedSessions = sessions.slice(0, 10);

    // Save to local storage
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(trimmedSessions));
  }

  // Get all chat sessions
  public getChatSessions(): ChatSession[] {
    const sessionsJson = localStorage.getItem(this.STORAGE_KEY);
    if (!sessionsJson) {
      return [];
    }

    return JSON.parse(sessionsJson);
  }

  // Delete a chat session
  public deleteSession(sessionId: string): void {
    const sessions = this.getChatSessions();
    const filteredSessions = sessions.filter(session => session.id !== sessionId);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(filteredSessions));
  }
}